console.log("Начало view-js/src/main.tsx");

import React from "react";
import ReactDOM from "react-dom";

import Grid from "./gs-grid";

type renderFunction<T extends ru.bitec.presenter.model.PNode> = {
  (
    container: Element,
    presenter: T,
    presenterEventSource: ru.bitec.presenter.model.ui.externals.PresenterEventSource
  ): void;
};

class GsNativeJsApi {
  renderGrid!: renderFunction<ru.bitec.presenter.model.ui.treegrid.PTreeGridSite>;
}

declare global {
  let TreePresenterEvents: ITreePresenterEvents;

  interface ITreePresenterEvents {
    recordsChanged: string;
  }

  interface Window {
    gsNativeJsApi: GsNativeJsApi;
  }
}

(function (global: Window) {
  console.log("Инициализация window.gsNativeJsApi");

  global.gsNativeJsApi = new GsNativeJsApi();

  global.gsNativeJsApi.renderGrid = (
    container: Element,
    presenter: ru.bitec.presenter.model.ui.treegrid.PTreeGridSite,
    presenterEventSource: ru.bitec.presenter.model.ui.externals.PresenterEventSource
  ) => {
    console.log(" --> window.gsNativeJsApi.renderGrid()");

    ReactDOM.render(
      <Grid
        presenterEventSource={presenterEventSource}
        presenter={
          presenter as ru.bitec.presenter.model.ui.treegrid.PTreeGridSite
        }
      />,
      container
    );
  };
})(window);
