// Global-system proxy объект на Ag-grid. Принимает presenter.

console.log("Начало view-js/gs-grid.tsx");

import React, { useCallback, useEffect, useRef, useMemo } from "react";

//import { AgGridReact } from "ag-grid-react";
import { AgGridReact } from "@ag-grid-community/react";
import { ViewportRowModelModule } from "@ag-grid-enterprise/viewport-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import "./ag-theme-gs.css";

import {
  ColDef,
  ColGroupDef,
  GetDataPath,
  CellFocusedEvent,
  GridOptions,
  ICellRendererParams,
  IViewportDatasource,
  IViewportDatasourceParams,
  AnimateShowChangeCellRenderer,
  ICellRendererComp,
  RowNode,
  RowGroupOpenedEvent,
} from "@ag-grid-community/core";

import "@ag-grid-enterprise/core";

import { RowNodeEvent } from "@ag-grid-community/core/dist/cjs/es5/entities/rowNode";

const Grid = (props: IGridParams) => {
  console.log("-->Grid function body!");

  const handler = {
    dispatchEvent: (
      event: ru.bitec.presenter.model.ui.externals.PresenterEventAbst
    ) => {
      console.log(
        "--> handler.dispatchEvent: CATCH presenter event by dispatcher:" +
          event.getName()
      );
      const presenter = props.presenter;
      console.log("  props.presenter:" + presenter.getController());

      switch (event.getName()) {
        case ru.bitec.presenter.model.ui.storeview
          .DataStoreChangedPresenterEvent.staticName: {
          if (viewportDSParams_.current != undefined) {
            callSuccessCallbackViewPort(presenter, viewportDSParams_.current);
          }
        }
      }
    },
  };

  useEffect(() => {
    console.log("-->useEffect displayRegister!");
    props.presenterEventSource.subscribe(handler);
  });

  const gridRootDomContainerRef = useRef<HTMLDivElement>(null);

  function callSuccessCallbackViewPort(
    presenter: ru.bitec.presenter.model.ui.treegrid.PTreeGridSite,
    getRowParams: IViewportDatasourceParams
  ) {
    console.log("-->callSuccessCallback()");

    const rows: Array<any> = [];
    const orderList = presenter.getPStoreView().getRecordsOrder();

    let recordDataProvider: ru.bitec.presenter.model.ui.treegrid.PTreeRecordImpl.DataProviderImpl;
    let record: ru.bitec.presenter.model.ui.treegrid.PTreeRecord;
    const store = presenter.getPStoreView();

    const offset = store.getPagingOffset();
    let rowNum = offset;
    for (const recordNodeKey of orderList) {
      if (presenter.getPStoreView().getNodeManager().contains(recordNodeKey)) {
        record = presenter
          .getPStoreView()
          .getNodeManager()
          .get(
            recordNodeKey
          ) as ru.bitec.presenter.model.ui.treegrid.PTreeRecord;
        recordDataProvider = record.getDataProvider();

        const dataRecord: { [k: string]: any } = {};
        for (const columnConfig of presenter
          .getColumnConfigManager()
          .getPColumnConfigArray()) {
          const value = recordDataProvider.get(columnConfig.getName());
          dataRecord[columnConfig.getName()] = (value && value) || null;
        }
        dataRecord["ROWID"] = recordDataProvider.getRowId();
        //dataRecord["HASCHILDREN"] = record.hasChildren();

        rows[rowNum] = dataRecord;
      } else {
        console.log(
          "  sendRecordsToGrid -> not found node record:" + recordNodeKey
        );
      }
      rowNum++;
    }

    console.log(
      "  recordCount:" +
        store.getRecordCount() +
        " getPagingOffset():" +
        store.getPagingOffset() +
        " isAllRowsFetched():" +
        presenter.isAllRowsFetched() +
        " getKeyArray.length:" +
        store.getNodeManager().getKeyArray().length
    );

    //TODO: надо еще разобраться чтобы правильно отсылать количество записей когда все загружено,
    // если передавать не -1 то подзагрузки больше не будет.
    const allloaded = presenter.isAllRowsFetched();
    // magic потому что есть сомнения что это за количество строк
    const magicRecordCount = (allloaded && rows.length) || -1;
    getRowParams.setRowCount(presenter.getPStoreView().getRecordCount());

    getRowParams.setRowData(rows);

    console.log(
      "  lastRow: " + ((rows.length > 0 && rows[rows.length - 1].ID) || "null")
    );
    console.log("  rows.length: " + magicRecordCount);

    rowNum = 0;
    for (const recordNodeKey of orderList) {
      if (presenter.getPStoreView().getNodeManager().contains(recordNodeKey)) {
        record = presenter
          .getPStoreView()
          .getNodeManager()
          .get(
            recordNodeKey
          ) as ru.bitec.presenter.model.ui.treegrid.PTreeRecord;

        const rowNode = getRowParams.getRow(offset + rowNum);
        rowNode.setUiLevel(record.getLevel());
        rowNode.setExpanded(record.getExpanded());
        rowNode.setGroup(record.hasChildren());
      }
      rowNum++;
    }
  }

  const onReady = useCallback((params: GridOptions) => {
    console.log("-->onReady");

    const columnDefs: any[] = [];

    for (const columnConfig of props.presenter
      .getColumnConfigManager()
      .getPColumnConfigArray()) {
      const columnDef = {
        field: columnConfig.getName(),
        headerName: columnConfig.getVisibleCaption(),
        resizable: true,
        initialHide: !columnConfig.isVisible(),
      };

      columnDefs.push(columnDef);

      viewportDSParams_.current?.setRowCount(
        props.presenter.getPStoreView().getRecordCount()
      );
    }

    const firstVisibleColumnDef: any = columnDefs.find(
      (value: any, index: number, obj: any[]) => !columnDefs[index].initialHide
    );

    if (firstVisibleColumnDef) {
      firstVisibleColumnDef.cellRenderer = "agGroupCellRenderer";
      firstVisibleColumnDef.showRowGroup = true;
    }

    columnDefs[0].cellRenderer = "agGroupCellRenderer";
    columnDefs.push({ field: "ROWID" });

    params.api?.setColumnDefs(columnDefs);

    params.api?.sizeColumnsToFit();
  }, []);

  const onCellFocused = useCallback((params: CellFocusedEvent) => {
    if (params.rowIndex != null) {
      console.log("-->onCellFocused callback in ts:");

      props.presenter
        .getController()
        .changeSelectedAndFocusedRecords(
          ru.bitec.engine.core.model.ui.grid.GridSelectActionEnum
            .ClearSelection,
          null,
          null,
          null,
          null,
          null,
          params.api.getModel().getRow(params.rowIndex)?.data.ROWID,
          ""
        );
    }
  }, []);

  const onRowGroupChanged = useCallback((params: RowGroupOpenedEvent) => {
    console.log("onRowGroupChanged");

    if (!params.event || params.node.data == null) {
      return;
    }

    if (params.expanded) {
      props.presenter.getController().expand(params.node.data.ROWID);
    } else {
      props.presenter.getController().collapse(params.node.data.ROWID);
    }
  }, []);
  // class RowTreeRenderer implements ICellRendererComp {
  //   eGui!: HTMLDivElement;
  //   init(params: ICellRendererParams) {
  //     params.colDef?.colId
  //     this.eGui = document.createElement("div");
  //     this.eGui.innerHTML = "" + params.rowIndex;
  //   }
  //   refresh(params: ICellRendererParams): boolean {
  //     return false;
  //   }
  //   getGui(): HTMLElement {
  //     return this.eGui;
  //   }
  // }

  const RowTreeRenderer = (props: ICellRendererParams) => {
    return (
      <div style={{ marginLeft: 10 * 2 }}>
        {props.valueFormatted ? props.valueFormatted : props.value}
      </div>
    );
  };

  const onDataFirstRender = useCallback((params: GridOptions) => {
    console.log("-->onDataFirstRender");

    params.columnApi?.autoSizeAllColumns(false);
  }, []);

  const getRowId = useCallback(
    (params: any) =>
      params.data.ID
        ? params.data.ID
        : (() => {
            console.log("WARNING! ID is Null");
            return null;
          })(),
    []
  );

  const viewportDSParams_ = useRef<IViewportDatasourceParams>();
  const viewportDatasource: IViewportDatasource = {
    init: (params: IViewportDatasourceParams) => {
      viewportDSParams_.current = params;
    },
    /** Tell the viewport what the scroll position of the grid is, so it knows what rows it has to get. */
    setViewportRange: (firstRow: number, lastRow: number) => {
      props.presenter.getController().load(firstRow, lastRow - firstRow + 1);
    },
  };

  return (
    <div
      ref={gridRootDomContainerRef}
      className="ag-theme-alpine"
      style={{ height: "100%", width: "100%" }}
    >
      <AgGridReact
        treeData={true}
        rowModelType={"viewport"}
        viewportDatasource={viewportDatasource}
        onGridReady={onReady}
        onCellFocused={onCellFocused}
        getRowId={getRowId}
        onFirstDataRendered={onDataFirstRender}
        modules={[ViewportRowModelModule, RowGroupingModule]}
        onRowGroupOpened={onRowGroupChanged}
        groupDisplayType={"custom"}
        viewportRowModelPageSize={50}
        viewportRowModelBufferSize={100}
      ></AgGridReact>
    </div>
  );
};

interface IGridParams {
  presenter: ru.bitec.presenter.model.ui.treegrid.PTreeGridSite;
  presenterEventSource: ru.bitec.presenter.model.ui.externals.PresenterEventSource;
}

export { Grid as default };
